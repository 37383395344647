<template>
  <v-container fluid>
    <v-card elevation="6">
      <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header">
        <span>{{
            "" || (this.goodReceipt && this.goodReceipt.goodsReceiptName)
          }}</span>
        <v-spacer/>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
                v-if="Protected == 0"
                v-on="on"
                :disabled="loadingCommit || makeChange"
                :loading="loadingCommit"
                color="primary"
                icon
                @click="commitItems"
            >
              <v-icon>check</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("erp.lang_commit_lock") }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
                v-if="Protected == 0"
                v-on="on"
                :disabled="loadingSave"
                :loading="loadingSave"
                color="success"
                icon
                @click="save"
            >
              <v-icon>save</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("generic.lang_save") }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
                v-if="Protected == 0"
                v-on="on"
                color="error"
                icon
                @click="clear"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("generic.lang_delete") }}</span>
        </v-tooltip>
      </v-card-title>
      <v-divider v-if="Protected == 0" class="pa-0 ma-0"/>
      <v-card-text class="pa-0 ma-0">
        <v-form ref="form" lazy-validation onsubmit="return false">
          <v-row align="center" justify="center">
            <v-col
                v-if="Protected == 0"
                class="mx-auto"
                cols="12"
                md="6"
                sm="8"
            >
              <v-card-text>
                <v-menu
                    max-height="300px"
                    class="white"
                    v-model="items_menu"
                    open-on-focus
                    offset-y
                    close-on-content-click
                    :close-on-click="false"
                >
                  <template v-slot:activator="{}">
                    <v-text-field
                        autofocus
                        :placeholder="$t('datatables.lang_search')"
                        prepend-inner-icon="mdi-database-search"
                        hide-details
                        outlined
                        ref="autocomplete"
                        dense
                        v-model="search"
                        :loading="isLoading"
                        :data-layout="KEYBOARD.KEYSETS.NORMAL"
                        @focus="showTouchKeyboard"
                        @keyup="items_menu = true"
                        @click="items_menu = true"
                    >
                    </v-text-field>
                  </template>
                  <v-list v-if="Array.isArray(entries) && entries.length > 0">
                    <v-list-item
                        @click="scan(item)"
                        :key="item.id+'_'+index"
                        v-for="(item, index) in entries"
                    >
                      <v-list-item-content>
                        {{ item.name }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <div v-else class="text-center w-100">{{ $t("generic.lang_noItemsFound") }}</div>
                </v-menu>

                <!--<v-autocomplete
                  ref="autocomplete"
                  v-model="current"
                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                  :filter="Filter"
                  :items="this.entries"
                  :loading="isLoading"
                  :placeholder="this.$t('datatables.lang_search')"
                  :search-input.sync="search"
                  autofocus
                  hide-selected
                  item-text="name"
                  item-value="id"
                  outlined
                  prepend-inner-icon="mdi-database-search"
                  return-object
                  @change="scan"
                  @focus="showTouchKeyboard"
                >
                  <template v-slot:item="{ item }">
                    <span v-if="item.color.length > 0 && item.size.length > 0"
                      >{{ item.name }} ({{
                        "color:" + item.color + " , " + "size:" + item.size
                      }})
                    </span>
                    <span
                      v-else-if="item.color.length > 0 && item.size.length == 0"
                      >{{ item.name }} ({{ "color:" + item.color }})
                    </span>
                    <span
                      v-else-if="item.color.length == 0 && item.size.length > 0"
                      >{{ item.name }} ({{ "size:" + item.size }})
                    </span>
                    <span v-else>
                      {{ item.name }}
                    </span>
                  </template>
                </v-autocomplete>-->
              </v-card-text>
            </v-col>
            <v-col
                v-if="this.selectedItems && this.selectedItems.length > 0"
                cols="12"
            >
              <v-divider class="ma-0 pa-0"/>
            </v-col>
            <v-col class="mt-0 pt-0" cols="12">
              <v-form ref="itemsForm" lazy-validation>
                <v-data-table
                    :headers="headers"
                    :items="this.selectedItems"
                    :items-per-page="15"
                    class="elevation-0"
                >
                  <template v-slot:item.ean="{ item }">
                    <strong class="primary--text">{{ item.ean }}</strong>
                  </template>

                  <template v-slot:item.qty="{ item }">
                    <v-text-field
                        v-model="item.qty"
                        :rules="[(v) => !!v]"
                        class="mt-7"
                        dense
                        outlined
                        type="number"
                        :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                        @focus="showTouchKeyboard"
                    />
                  </template>

                  <template v-slot:item.bestBeforeDate="{ item }">
                    <strong v-if="item.bestBeforeDate !== 0">{{
                        item.bestBeforeDate
                      }}</strong>
                  </template>

                  <template v-slot:item.color="{ item }">
                    <v-chip
                        v-if="item.color !== undefined && item.color !== ''"
                        :color="item.color"
                        class="colors"
                    ></v-chip>
                  </template>

                  <template v-slot:item.buy_price="{ item }">
                    <v-text-field
                        v-model="item.buy_price"
                        :rules="[(v) => !!v]"
                        class="mt-7"
                        dense
                        outlined
                        
                        :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                        @focus="showTouchKeyboard"
                        type="number"
                    />
                  </template>

                  <template v-slot:item.serialNo="{ item }">
                    <div>{{ item.serialNo && item.serialNo.join(" , ") }}</div>
                    <v-btn
                        v-if="item.hasSerialNo"
                        class="mx-auto"
                        @click="openSerialNoDialog(item)"
                        depressed
                        color="success"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-btn
                        v-if="Protected == 0"
                        color="primary"
                        icon
                        @click="editItem(item)"
                    >
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="Protected == 0"
                        color="error"
                        icon
                        @click="deleteItem(item)"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-form>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-text v-if="chargeDue">
        <v-row justify="center">
          <v-dialog v-model="chargeDue" max-width="500" persistent>
            <!--if hasChargenNo && hasMHD dialog-->
            <v-card class="pa-3">
              <v-card-text class="">
                <v-form ref="chargeDue" lazy-validation onsubmit="return false">
                  <v-row>
                    <v-col class="pb-0 mb-0 pt-0 mt-0" cols="12">
                      <v-date-picker
                          v-model="selected.bestBeforeDate"
                          class="elevation-0"
                          full-width
                          show-current
                      />
                    </v-col>
                    <v-col class="pb-0 mb-0" cols="12">
                      <BaseDateInput
                          v-model="selected.bestBeforeDate"
                          :rules="[(v) => !!v]"
                          class="pb-0 mb-0"
                          dense
                          outlined
                          type="date"
                      />
                    </v-col>

                    <v-col class="" cols="12">
                      <v-text-field
                          v-model="selected.batchNo"
                          :rules="[(v) => !!v]"
                          dense
                          :label="$t('erp.lang_enterChargeNo')"
                          outlined
                          @keypress.enter="saveChargeDue"
                      />
                    </v-col>

                    <v-col class="" cols="12">
                      <v-text-field
                          v-model="selected.qty"
                          :label="this.$t('erp.lang_quantity')"
                          :rules="[(v) => !!v]"
                          dense
                          outlined
                          type="number"
                          :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                          @focus="showTouchKeyboard"
                          @keypress.enter="saveItem"
                      />
                    </v-col>
                    <v-col class="text-right pa-0 ma-0" cols="12">
                      <v-btn color="error" text @click="close">
                        {{ this.$t("generic.lang_cancel") }}
                      </v-btn>
                      <v-btn color="primary" text @click="saveChargeDue">
                        {{ this.$t("generic.lang_save") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
      </v-card-text>

      <v-card-text v-if="hasChargenNo">
        <v-row justify="center">
          <v-dialog v-model="hasChargenNo" max-width="500" persistent>
            <!--if hasChargenNo dialog-->
            <v-card class="pa-3">
              <v-card-text class="">
                <v-form
                    ref="hasChargenNo"
                    lazy-validation
                    onsubmit="return false"
                >
                  <v-row>
                    <v-col class="" cols="12">
                      <v-text-field
                          v-model="selected.batchNo"
                          :rules="[(v) => !!v]"
                          autofocus
                          dense
                          :label="$t('erp.lang_enterChargeNo')"
                          outlined
                          @keypress.enter="saveChargeNO"
                      />
                    </v-col>

                    <v-col class="text-right pa-0 ma-0" cols="12">
                      <v-btn color="error" text @click="close">
                        {{ this.$t("generic.lang_cancel") }}
                      </v-btn>
                      <v-btn color="primary" text @click="saveChargeNO">
                        {{ this.$t("generic.lang_save") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
      </v-card-text>

      <v-card-text v-if="hasMHD">
        <v-row justify="center">
          <v-dialog v-model="hasMHD" max-width="500" persistent>
            <!--if hasMHD dialog-->
            <v-card class="pa-3">
              <v-card-text class="">
                <v-form ref="hasMHD" lazy-validation onsubmit="return false">
                  <v-row>
                    <v-col class="pb-0 mb-0 pt-0 mt-0" cols="12">
                      <v-date-picker
                          v-model="selected.bestBeforeDate"
                          class="elevation-0"
                          full-width
                          show-current
                      />
                    </v-col>
                    <v-col class="pb-0 mb-0" cols="12">
                      <BaseDateInput
                          v-model="selected.bestBeforeDate"
                          :rules="[(v) => !!v]"
                          autofocus
                          class="pb-0 mb-0"
                          dense
                          outlined
                          type="date"
                          @keypress.enter="saveMHD"
                      />
                    </v-col>

                    <v-col class="text-right pa-0 ma-0" cols="12">
                      <v-btn color="error" text @click="close">
                        {{ this.$t("generic.lang_cancel") }}
                      </v-btn>
                      <v-btn color="primary" text @click="saveMHD">
                        {{ this.$t("generic.lang_save") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
      </v-card-text>

      <!-- serial numbers dialog -->
      <v-dialog
          v-if="this.editedItem && this.editedItem.hasSerialNo"
          v-model="serialNoDialog"
          max-width="500"
          persistent
      >
        <v-card class="pa-0">
          <v-card-title class="card-header">{{ $t('erp.lang_serialNumbers') }}</v-card-title>
          <v-card-text class="pt-3">
            <v-form ref="hasMHD" lazy-validation onsubmit="return false">
              <v-row>
                <v-col class="" cols="12">
                  <v-form lazy-validation ref="serialNoForm">
                    <v-text-field
                        autofocus
                        v-model="editedItem.serialNo[i]"
                        @keypress.enter="addElement"
                        :rules="[(v) => !!v]"
                        class="mt-2"
                        hide-details
                        append-icon="delete"
                        @click:append="deleteElement(i)"
                        :value="number"
                        :key="i"
                        outlined
                        dense
                        :label="$t('erp.lang_serialnumber') + ' ' + (i + 1)"
                        v-for="(number, i) in editedItem.serialNo"
                    />
                  </v-form>

                  <div class="w-100 text-right">
                    <v-spacer/>
                    <v-btn
                        class="mx-auto"
                        @click="addElement"
                        depressed
                        color="success"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </div>
                </v-col>

                <v-col class="text-right pa-0 ma-0" cols="12">
                  <v-btn color="error" text @click="close">
                    {{ this.$t("generic.lang_cancel") }}
                  </v-btn>
                  <v-btn color="primary" text @click="saveSerialNo">
                    {{ this.$t("generic.lang_save") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- end serial numbers dialog -->
      <v-card-text v-if="edit && editedItem">
        <v-row justify="center">
          <v-dialog v-model="edit" max-width="500" persistent>
            <!--editting dialog-->
            <v-card class="pa-3">
              <v-card-text class="">
                <v-form ref="edit" lazy-validation onsubmit="return false">
                  <v-row>
                    <v-col
                        v-if="editedItem.bestBeforeDate"
                        class="pb-0 mb-0 pt-0 mt-0"
                        cols="12"
                    >
                      <v-date-picker
                          v-model="editedItem.bestBeforeDate"
                          class="elevation-0"
                          full-width
                          show-current
                      />
                    </v-col>
                    <v-col
                        v-if="editedItem.bestBeforeDate"
                        class="pb-0 mb-0"
                        cols="12"
                    >
                      <BaseDateInput
                          v-model="editedItem.bestBeforeDate"
                          :rules="[(v) => !!v]"
                          autofocus
                          class="pb-0 mb-0"
                          dense
                          outlined
                          type="date"
                      />
                    </v-col>

                    <v-col v-if="editedItem.batchNo" class="" cols="12">
                      <v-text-field
                          v-model="editedItem.batchNo"
                          :rules="[(v) => !!v]"
                          autofocus
                          dense
                          :label="$t('erp.lang_enterChargeNo')"
                          outlined
                          @keypress.enter="saveItem"
                      />
                    </v-col>

                    <v-col class="" cols="12">
                      <v-text-field
                          v-model="editedItem.qty"
                          :label="this.$t('erp.lang_quantity')"
                          :rules="[(v) => !!v]"
                          dense
                          outlined
                          type="number"
                          
                          :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                          @focus="showTouchKeyboard"
                          @keypress.enter="saveItem"
                      />
                    </v-col>

                    <v-col class="text-right pa-0 ma-0" cols="12">
                      <v-btn color="error" text @click="close">
                        {{ this.$t("generic.lang_cancel") }}
                      </v-btn>
                      <v-btn color="primary" text @click="saveItem">
                        {{ this.$t("generic.lang_save") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
      </v-card-text>
    </v-card>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
                <vue-touch-keyboard
                    v-if="touchKeyboard.visible"
                    id="onScreenKeyboard"
                    :accept="hideTouchKeyboard"
                    :cancel="hideTouchKeyboard"
                    :defaultKeySet="touchKeyboard.keySet"
                    :input="touchKeyboard.input"
                    :layout="touchKeyboard.layout"
                    :options="touchKeyboard.options"
                    class="internalWidthExpanded"
                />
              </div>
  </v-container>
</template>

<script>
//configs
import mixin from "../../../../mixins/KeyboardMixIns";
import {Events} from "@/plugins/events";
import {ENDPOINTS} from "@/config";
import Birthdaypicker from "../../../common/birthdaypicker";
import Template from "../../../settings/customerdisplay/Template";
import {debounce} from "lodash";
import BaseDateInput from "@/components/common/BaseDateInput.vue";

export default {
  name: "GoodsReceiptDetailsComponent",

  components: {Template, Birthdaypicker, BaseDateInput},

  mixins: [mixin],

  data: () => {
    return {
      ENDPOINT: ENDPOINTS,
      goodReceipt: null,
      items_menu: false,
      hasChargenNo: false,
      hasMHD: false,
      chargeDue: false,
      entries: [],
      selectedItems: [
        /*{ean , name, qty, bestBeforeDate , batchNo, serialNo, color,size}*/
      ],
      Protected: false,
      isLoading: false,
      selected: null,
      search: null,
      loadingSelect: false,
      current: null,
      editedItem: null,
      editedIndex: -1,
      edit: false,
      loadingSave: false,
      loadingCommit: false,
      loadingClear: false,
      makeChange: false,
      items: [],
      hasSerialNo: false,
      serialNoDialog: false,
      searchInput: "",
    };
  },
  methods: {
    /**
     * delete element from creation form
     * @param index
     */
    deleteElement(index) {
      this.editedItem.serialNo.splice(index, 1);
    },
    addElement() {
      if (!this.$refs.form.validate()) return;
      if (!Array.isArray(this.editedItem.serialNo))
        this.editedItem.serialNo = [];

      this.editedItem.serialNo.push("");
    },
    close() {
      this.hasChargenNo =
          this.hasMHD =
              this.chargeDue =
                  this.hasSerialNo =
                      this.edit =
                          false;
      this.selected = this.editedItem = null;
    },
    openSerialNoDialog(item) {
      this.editedItem = Object.assign({}, item);
      this.editedIndex = this.selectedItems.indexOf(item);
      if (!Array.isArray(this.editedItem.serialNo))
        this.editedItem.serialNo = [""];
      else if (this.editedItem.serialNo.length === 0)
        this.editedItem.serialNo = [""];
      this.serialNoDialog = true;
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.editedIndex = this.selectedItems.indexOf(item);
      this.edit = true;
    },
    clear() {
      let self = this;
      this.$swal({
        title: this.$t("generic.lang_doYouReallyWantToClear") + " " + self.goodReceipt.goodsReceiptName + "?",
        text: this.$t("generic.lang_doYouReallyWantToClear") + " " + self.goodReceipt.goodsReceiptName + "?",
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.deleteData([self.$route.params.id]);
        },
        allowOutsideClick: () => !this.$swal.isLoading,
      });
    },
    deleteItem(item) {
      let self = this;
      this.$swal({
        title: this.$t("generic.lang_doYouReallyWantToDelete") + " " + item.name + "?",
        text: this.$t("generic.lang_doYouReallyWantToDelete") + " " + item.name + "?",
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          const index = self.selectedItems.indexOf(
              self.getItemByIDandEAN(item.ean)
          );
          this.selectedItems.splice(index, 1);
          this.makeChange = true;
        },
        allowOutsideClick: () => !this.$swal.isLoading,
      });
    },
    saveChargeNO() {
      if (!this.$refs.hasChargenNo.validate()) return;
      this.push(this.selected);
      this.hasChargenNo = false;
      this.selected = null;
    },
    saveMHD() {
      if (!this.$refs.hasMHD.validate()) return;
      this.push(this.selected);
      this.hasMHD = false;
      this.selected = null;
    },
    saveChargeDue() {
      if (!this.$refs.chargeDue.validate()) return;
      this.push(this.selected);
      this.chargeDue = false;
      this.selected = null;
    },
    saveItem() {
      if (!this.$refs.edit.validate()) return;

      Object.assign(this.selectedItems[this.editedIndex], this.editedItem);

      this.editedIndex = -1;
      this.editedItem = null;
      this.edit = false;
    },
    saveSerialNo() {
      if (!this.$refs.serialNoForm.validate()) return;

      this.editedItem.serialNo = this.editedItem.serialNo.filter(
          (n) => n && n.length > 0
      );

      if (this.editedItem.qty < this.editedItem.serialNo.length)
        this.editedItem.qty = this.editedItem.serialNo.length;
      Object.assign(this.selectedItems[this.editedIndex], this.editedItem);

      this.editedIndex = -1;
      this.editedItem = null;
      this.serialNoDialog = false;
    },
    setHumainDate() {
      if (this.selectedItems) {
        this.selectedItems.forEach((item) => {
          if (item.bestBeforeDate == 0) item.bestBeforeDate = "";
          else
            item.bestBeforeDate =
                new Date(item.bestBeforeDate * 1000).getFullYear() +
                "-" +
                (new Date(item.bestBeforeDate * 1000).getMonth() + 1) +
                "-" +
                new Date(item.bestBeforeDate * 1000).getDate();
        });
      }
    },
    setUnixTimestamp() {
      let tmp = [];
      if (this.selectedItems) {
        this.selectedItems.forEach((item) => {
          let tmpItem = Object.assign({}, item);
          if (tmpItem.bestBeforeDate == 0) tmpItem.bestBeforeDate = "";
          else
            tmpItem.bestBeforeDate = Math.round(
                +new Date(item.bestBeforeDate).getTime() / 1000
            );
          tmp.push(tmpItem);
        });
      }
      return tmp;
    },
    deleteData: function (idsToDelete = []) {
      let self = this;
      this.loadingClear = false;
      this.axios
          .post(ENDPOINTS.ERP.GOODSRECEIPT.CLEAR, {
            storageTransferIDs: idsToDelete,
          })
          .then((res) => {
            if (res.data.status === "SUCCESS") {
              Events.$emit("showSnackbar", {
                message: this.$t("erp.lang_itemSuccessfulDeleted"),
                color: "success",
              });

              self.selectedItems = [];
              self.$router.replace("/erp/storage/GoodsReceipt");
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_errorOccurred"),
                color: "error",
              });
            }
          })
          .catch((err) => {
            Events.$emit("showSnackbar", {
              message: err.message,
              color: "error",
            }).finally(() => {
              self.loadingClear = false;
            });
          });
    },
    commitItems() {
      this.loadingCommit = true;
      this.axios
          .post(ENDPOINTS.ERP.GOODSRECEIPT.COMMIT, {
            storageTransferID: this.$route.params.id,
          })
          .then((res) => {
            if (res.data.status === "SUCCESS") {
              Events.$emit("showSnackbar", {
                message: this.$t("erp.lang_itemsSuccessfullImported"),
                color: "success",
              });
              this.$router.replace("/erp/storage/GoodsReceipt");
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_errorOccurred"),
                color: "error",
              });
            }
          })
          .catch((err) => {
            Events.$emit("showSnackbar", {
              message: err.message,
              color: "error",
            });
          })
          .finally(() => {
            this.loadingCommit = false;
          });
    },
    save() {
      //save items
      if (!this.$refs.itemsForm.validate()) return;
      let form = {};
      this.loadingSave = true;
      form.storageTransferID = this.$route.params.id;
      form.items = this.setUnixTimestamp();

      this.axios
          .post(ENDPOINTS.ERP.GOODSRECEIPT.SAVEITEMS, form)
          .then((res) => {
            if (res.data.status === "SUCCESS") {
              Events.$emit("showSnackbar", {
                message: this.$t("erp.lang_itemsSuccessfullImported"),
                color: "success",
              });

              this.makeChange = false;
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_errorOccurred"),
                color: "error",
              });
            }
          })
          .catch((err) => {
            Events.$emit("showSnackbar", {
              message: err.message,
              color: "error",
            });
          })
          .finally(() => {
            this.loadingSave = false;
          });
    },
    push(item) {
      if (item) {
        this.makeChange = true;
        this.selectedItems.push({
          id: item.id,
          ean: item.ean,
          name: item.name,
          qty: item.qty || 1,
          bestBeforeDate: item.bestBeforeDate,
          batchNo: item.batchNo,
          serialNo: item.serialNo,
          color: item.color,
          size: item.size,
          hasSerialNo: this.hasSerialNo,
          buy_price: item.buy_price,
        });
      }
    },
    scan(pickedItem) {
      this.current = {...pickedItem};
      this.selected = Object.assign({}, this.current);

      this.$nextTick(() => {
        if (this.isExists(this.selected.ean)) {
          //check if exist then increase qty
          const index = this.selectedItems.indexOf(
                  this.getItemByIDandEAN(this.selected.ean)
              ),
              item = this.getItemByIDandEAN(this.selected.ean);
          item.qty++;
          this.makeChange = true;
          Object.assign(this.selectedItems[index], item);
        } else {
          this.chargeDue = this.selected.hasChargenNo && this.selected.hasMHD;
          this.hasChargenNo =
              this.selected.hasChargenNo && !this.selected.hasMHD;
          this.hasMHD = !this.selected.hasChargenNo && this.selected.hasMHD;
          this.hasSerialNo = this.selected.hasSerialNo === 1;

          if (!this.hasChargenNo && !this.hasMHD && !this.chargeDue)
            this.push(this.selected);
        }

        this.$refs.autocomplete.reset();
        this.$nextTick(() => {
          this.$refs.autocomplete.focus();
          this.showTouchKeyboardWithoutEvent();
        })
      });
    },
    isExists(ean) {
      const result = this.selectedItems.filter((item) => item.ean === ean);
      //console.log(result)
      return result.length > 0;
    },
    getItemByIDandEAN(ean) {
      const result = this.selectedItems.filter((item) => item.ean === ean);
      if (result.length > 0) return result[0];
      return null;
    },
    Filter(item, queryText, itemText) {
      //return all item whose names starts with "queryText"
      return (
          itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
          item.ean.toLowerCase().includes(queryText.toLowerCase()) ||
          item.q.toLowerCase().includes(queryText.toLowerCase()) ||
          item.name.toLowerCase().includes(queryText.toLowerCase())
      );
    },
    loadGoodReceiptDetails() {
      this.axios
          .post(this.ENDPOINT.ERP.GOODSRECEIPT.SHOW, {
            goodsReceiptUUID: this.$route.params.id,
          })
          .then((res) => {
            if (res.data.formfillData) {
              this.goodReceipt = res.data.formfillData.textFields;
              this.selectedItems = res.data.items;
              this.Protected = res.data.protected;
              this.setHumainDate();
            }
          })
          .catch((err) => {
            this.loadingSelect = false;
            Events.$emit("showSnackbar", {
              color: "error",
              message: err.message,
            });
          });
    },
  },
  async mounted() {
    this.items = await this.$store.dispatch("items/getItems");

    this.loadGoodReceiptDetails();
  },
  computed: {
    currentDate: function () {
      let dt = new Date();
      return dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate();
    },

    headers: function () {
      return [
        {text: this.$t("erp.lang_ware_create_id"), value: "ean"},
        {text: this.$t("erp.lang_itemname"), value: "name", sortable: false},
        {
          text: this.$t("erp.lang_quantity"),
          value: "qty",
        },
        {text: this.$t('erp.lang_bestBeforeDate'), value: "bestBeforeDate"},
        {text: this.$t('erp.lang_chargeNo'), value: "batchNo", sortable: false},
        {text: this.$t('erp.lang_serialnumber'), value: "serialNo"},
        {
          text: this.$t("erp.lang_warecreate_color"),
          value: "color",
          sortable: false,
        },
        {
          text: this.$t("generic.lang_size"),
          value: "size",
          sortable: false,
        },
        {text: this.$t('erp.lang_ware_create_bprice'), value: "buy_price", sortable: false},
        {text: "", value: "actions"},
      ];
    },
  },
  watch: {
    items_menu(v) {
      if (v) this.entries = [];
    },
    search(v) {

      clearTimeout(this.awaitingSearch);
      this.items_menu = true;
      this.awaitingSearch = setTimeout(() => {
        if ((v && v.length === 0) || !!v == false) {
          return;
        }


        // Items have already been requested
        //if (this.isLoading) return;

        //clear old data
        /*if ((this.current && this.current.name !== v) || (this.current && this.current.name === this.search)) {
          this.$refs.autocomplete.reset();
          return;
        }*/

        this.isLoading = true
        this.axios.post(ENDPOINTS.ERP.ITEM.SEARCH, {
          query: v
        }).then((res) => {
          if (res.data.status === "SUCCESS") {
            this.entries = res.data.data.foundItems;
          }
        }).catch(() => {

        }).finally(() => {
          this.isLoading = false;
        })
      }, 500);
    },
  },
};
</script>

<style>
.colors {
  width: 10px !important;
  height: 25px !important;
}
</style>

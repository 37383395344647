<template>
    <div>
        <page-title :heading="$t('erp.lang_nav_warein') " :icon=icon :subheading="$t('erp.lang_nav_warein')"
                    show-previous-button url-previous="/erp/storage/GoodsReceipt"></page-title>
        <div class="app-main__inner">
            <GoodsReceiptEdit></GoodsReceiptEdit>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../../Layout/Components/PageTitle.vue";
    import GoodsReceiptEdit from "../../../../components/erp/storage/goodreceipt/GoodsReceiptDetailsComponent";

    export default {
        components: {
            PageTitle,
            GoodsReceiptEdit
        },
        props: ["id"],
        data: () => ({
            icon: 'pe-7s-box1 icon-gradient bg-tempting-azure',
        })
    }
</script>